import React from 'react'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import CloseIcon from 'shared/icons/close-icon'
import SearchIcon from 'shared/icons/search-icon'

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  error?: string
  inputClassName?: string
  onClear?: () => void
  placeholder?: string
  description?: string
  testAttributePostfix?: string
  isPreFetching?: boolean
  labelClassName?: string
}

function FormInputSearch({
  label,
  value,
  inputClassName,
  onChange,
  onFocus,
  maxLength,
  error,
  placeholder,
  onClear,
  description,
  testAttributePostfix,
  isPreFetching,
  labelClassName,
  ...rest
}: InputProps) {
  const { t } = useTranslation()

  return (
    <label
      className={twMerge(
        'relative flex flex-col gap-1 text-sm font-medium',
        labelClassName,
        rest.className,
      )}
    >
      {label && <span>{label}</span>}
      {value === '' ? (
        <SearchIcon
          className={`absolute right-4 z-10 h-[21px] w-[21px] translate-y-1/2 scale-75 ${
            label && 'top-[24px]'
          }`}
        />
      ) : (
        <CloseIcon
          onClick={onClear}
          className={`absolute right-2 z-10 h-[21px] w-[21px] translate-y-1/2 scale-75 cursor-pointer fill-darkblue ${
            label && 'top-[24px]'
          } `}
        />
      )}
      <input
        className={`main-transition-colors relative w-full rounded-lg border text-darkblue ${
          error ? 'border-danger' : 'border-gray/30'
        } py-2.5 pl-4 pr-8 placeholder:text-gray-300/70 focus:border-blue focus:outline-none ${
          isPreFetching
            ? 'animate-pulse bg-gray-600/40 text-transparent disabled:bg-gray-600/40'
            : 'disabled:bg-gray-200/40'
        } ${inputClassName || ''}`}
        onKeyDown={evt =>
          rest.type === 'number' && ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
        }
        type={rest.type || 'text'}
        disabled={rest.disabled || isPreFetching}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        maxLength={maxLength}
        placeholder={placeholder || t('global.search')}
        spellCheck={false}
        {...rest}
      />
      {description && <span className={'text-sm opacity-70'}>{description}</span>}
      {error && <p className="text-sm text-danger">{error}</p>}
    </label>
  )
}

export default FormInputSearch
